@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #fafafa !important;
}

.breadcrumb-link {
  color: grey !important;
  text-decoration: none !important;
  /* font-size: 1.125rem !important; */
}

.breadcrumb-link:hover {
  color: #8c8c8c !important;
}

.breadcrumb-link-active {
  color: #01c38d !important;
  text-decoration: none !important;
  border-image-source: linear-gradient(90deg, #01c38d 12.66%, #7728b5 100%);
  background: linear-gradient(90deg, #01c38d 12.66%, #7728b5 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.breadcrumb-link-active:hover {
  color: #01c38d !important;
}

.margin-right {
  margin-left: 10px;
}

.margin-bottom {
  margin-bottom: 20px;
}

.i-active {
  display: flex !important;
  align-items: center !important;
  margin-right: 10px !important;
  color: #8c8c8c !important;
}

.margin-left-right {
  margin-left: 2% !important;
  margin-right: 2% !important;
  margin-top: 2% !important;
  margin-bottom: 2% !important;
}

.container-fuild-margin {
  padding-left: 4% !important;
  padding-right: 4% !important;
  padding-bottom: 2% !important;
}

.span-input {
  border: 0px solid #8097ae !important;
  border-left-width: 1px !important;
  border-left-style: solid !important;
  border-left-color: #ced4da !important;
  border-top-width: 1px !important;
  border-top-style: solid !important;
  border-top-color: #ced4da !important;
  border-bottom-width: 1px !important;
  border-bottom-style: solid !important;
  border-bottom-color: #ced4da !important;
  background: #ffffff !important;
  padding: 1rem 0rem 1rem 1rem !important;
  color: #8c8c8c !important;
}

.text-input {
  border: 0px solid #8097ae !important;
  border-right-width: 1px !important;
  border-right-style: solid !important;
  border-right-color: #ced4da !important;
  border-top-width: 1px !important;
  border-top-style: solid !important;
  border-top-color: #ced4da !important;
  border-bottom-width: 1px !important;
  border-bottom-style: solid !important;
  border-bottom-color: #ced4da !important;
  background: #ffffff !important;
  font-size: 18px !important;
  padding: 1rem 0.75rem 1rem 0.75rem !important;
  color: #8c8c8c !important;
}

.import-button {
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 1rem 4.75rem 1rem 4.75rem !important;
  /* border: 1px solid #01c38d ; */
  border-radius: 4px !important;
  margin-left: 1% !important;
  color: #ffffff !important;
  font-weight: 600 !important;
  width: 200px;
}

.border-radius {
  border-radius: 4px !important;
}

.flaot-import-button {
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 1rem 2.75rem 1rem 2.75rem !important;
  border-radius: 4px !important;
  margin-left: 1% !important;
  color: #ffffff !important;
  font-weight: 600 !important;
}

.flaot-border-radius {
  border-radius: 4px !important;
}

.font-size {
  font-size: 24px !important;
  font-weight: 600 !important;
}

.container-padding {
  padding: 1.5rem !important;
  background-color: #ffffff !important;
  border-radius: 4px !important;
}

.iSAVrt {
  background-color: transparent !important;
}

.kVrXuC {
  border: 1px solid #e2e2e2 !important;
  border-radius: 4px !important;
  background-color: #fafafa !important;
}

.dwPlXY {
  background-color: #ffffff !important;
}

.filter-div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 10px;
  box-shadow: -18px 0px 0px -17px #0000000f !important;
  background: #eff0f6 !important;
}

.filter-select {
  width: 12% !important;
}

@media only screen and (max-width: 1024px) {
  .import-button {
    padding: 1rem 3.75rem 1rem 3.75rem !important;
  }
}

@media only screen and (max-width: 600px) {
  .breadcrumb-link-active {
    font-size: 12px !important;
  }

  .breadcrumb-link-active {
    font-size: 1rem !important;
  }

  .import-button {
    padding: 1rem 1.75rem 1rem 1.75rem !important;
  }
}


.cancel {
  color: #5C6C84 !important;
  box-shadow: 0px 1.38px 4.14px 0px #13124212;
  border-radius: 8px !important;
  border: 1px solid #EFF0F6;
  font-size: 14 !important;

}

.apply {
  font-size: 14 !important;
  font-weight: 400 !important;
  border-radius: 8px !important;
}

.deepModal .ant-modal-close {
  top: 8px !important;

  color: #262626;
}